<template>
  <div style="background-color: black" class="flex flex-wrap justify-center items-center p-6 lg:p-32 bg-black h-full">
    <!--    Left col-->
    <div class="hidden lg:flex lg:w-1/2 xl:w-1/4 h-full text-white  flex-col justify-start p-10 bg-black rounded-l-lg">
      <h1 class="font-bold text-4xl leading-tight mb-16">Book the world’s top guest & shows!</h1>
      <div>
        <p>Don´t have an account?</p>
        <router-link class="text-pink-500 underline" :to="{name:'register-personal-information'}">Sign up for free.</router-link>
      </div>
    </div>
    <!--    Right col-->
    <div class="w-full lg:w-1/2 xl:w-2/5 bg-white rounded-lg lg:rounded-l-none lg:rounded-r-lg p-10 lg:h-full">
      <svg width="43" height="56" viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M41.1006 11.7431L41.1006 0.000118256L22.0454 0.000119089L22.0182 0.000100016L21.9909 0.000119091L20.5504 0.000119154L20.5504 0.0557107C10.6973 0.805204 2.93581 9.03743 2.93581 19.0824C2.93581 29.6213 11.4793 38.1648 22.0182 38.1648C32.557 38.1648 41.1005 29.6213 41.1005 19.0824C41.1005 19.0824 41.1005 19.0824 41.1005 19.0824L41.1006 19.0824L41.1006 11.7431ZM28.2361 19.2971C28.2361 15.7446 25.3562 12.8648 21.8038 12.8648C18.2514 12.8648 15.3716 15.7446 15.3716 19.2971C15.3716 22.8495 18.2514 25.7293 21.8038 25.7293C25.3562 25.7293 28.2361 22.8495 28.2361 19.2971Z"
              fill="url(#paint0_linear)"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.89612 41.3538C7.89612 41.3538 7.89612 41.3538 7.89612 41.3538L7.89606 41.3537C7.89608 41.3537 7.8961 41.3538 7.89612 41.3538ZM7.87496 41.3264C6.38958 39.4231 3.64441 39.0697 1.72454 40.5415C-0.205549 42.0213 -0.570634 44.7855 0.909103 46.7156L4.40384 44.0363C0.909103 46.7156 0.91039 46.7173 0.911699 46.719L0.914433 46.7225L0.920316 46.7302L0.933741 46.7475L0.967237 46.7901C0.992523 46.8221 1.02374 46.861 1.06089 46.9064C1.13518 46.9973 1.23336 47.1143 1.3555 47.2537C1.59954 47.5322 1.94066 47.9017 2.37945 48.3309C3.25499 49.1874 4.53291 50.2952 6.21842 51.3945C9.60668 53.6042 14.6495 55.7793 21.2844 55.7793C27.9192 55.7793 32.9621 53.6042 36.3503 51.3945C38.0359 50.2952 39.3138 49.1874 40.1893 48.3309C40.6281 47.9017 40.9692 47.5322 41.2133 47.2537C41.3354 47.1143 41.4336 46.9973 41.5079 46.9064C41.545 46.861 41.5762 46.8221 41.6015 46.7901L41.635 46.7475L41.6484 46.7302L41.6543 46.7225L41.6571 46.719C41.6584 46.7173 41.6597 46.7156 38.1649 44.0363L41.6597 46.7156C43.1394 44.7855 42.7743 42.0213 40.8442 40.5415C38.9244 39.0697 36.1792 39.4231 34.6938 41.3264C34.6928 41.3277 34.6916 41.3292 34.6901 41.331C34.6765 41.3477 34.6428 41.3883 34.5891 41.4496C34.4814 41.5725 34.295 41.7764 34.0305 42.0352C33.4993 42.5548 32.6671 43.2819 31.5392 44.0175C29.3006 45.4774 25.9032 46.972 21.2844 46.972C16.6655 46.972 13.2681 45.4774 11.0295 44.0175C9.90162 43.2819 9.06948 42.5548 8.5383 42.0352C8.27374 41.7764 8.08734 41.5725 7.97971 41.4496C7.926 41.3883 7.8923 41.3477 7.87867 41.331C7.8772 41.3292 7.87596 41.3277 7.87496 41.3264Z"
              fill="#F0F0F0"/>
        <defs>
          <linearGradient id="paint0_linear" x1="36.0891" y1="34.5546" x2="32.4284" y2="0.0360918"
                          gradientUnits="userSpaceOnUse">
            <stop stop-color="#6203D0"/>
            <stop offset="1" stop-color="#DC3CF6"/>
          </linearGradient>
        </defs>
      </svg>
      <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(login)" method="post">
          <div class="mb-10">
            <h2 class="font-bold text-3xl">Sign into your account</h2>
          </div>
          <div class="grid grid-cols-1 row-gap-6">
            <div>
              <ValidationProvider rules="required|email" vid="email" name="Email" v-slot="{ errors }">
                <label class="form-lable">Email</label>
                <input
                  v-model="form.email"
                  class="form-input w-full"
                  :class="{'border-red-500': errors.length}"
                  type="email"
                  placeholder="Email"
                >
                <p class="text-red-500 text-sm mt-1" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div>
              <ValidationProvider rules="required" name="Password" v-slot="{ errors }">
                <label class="form-lable">Password</label>
                <input
                  v-model="form.password"
                  class="form-input w-full"
                  :class="{'border-red-500': errors.length}"
                  type="password"
                  placeholder="Password"
                >
              </ValidationProvider>
            </div>
            <p class="text-red-500 text-sm mt-1" v-if="error">{{ error }}</p>

            <div class="flex justify-between flex-col lg:flex-row">
              <router-link class="text-indigo-500 mb-3 lg:mb-0" to="/register">Do not have an account?</router-link>
              <router-link class="text-indigo-500 mb-1" to="/reset-password">Forgot password?</router-link>
            </div>
          </div>

          <button class="btn bg-gray-600 mt-10 flex justify-center w-full lg:w-auto items-center disabled:opacity-50 h-14" type="submit" :disabled="loading">
            <span v-if="loading">
              <loading-icon class="h-3 px-3"/>
            </span>
            <span v-else>Sign In</span>
          </button>
        </form>
      </ValidationObserver>

      <div v-if="socialLoginEnabled" class="mt-6">
        <!-- disabled until credentials are provided -->
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-white text-gray-500">
              Or continue with
            </span>
          </div>
        </div>

        <div class="mt-6 grid grid-cols-2 gap-3">
          <div class="col-span-2 lg:col-span-1">
            <a :href="`${socialLoginUrl}/facebook`" class="w-full inline-flex items-center justify-center py-3 px-4 space-x-4 text-sm border border-gray-300 rounded-full shadow-sm bg-white text-gray-500">
              <FacebookLoginIcon class="w-5 h-5" />
              <span class="font-roboto">Sign in with Facebook</span>
            </a>
          </div>

          <div class="col-span-2 lg:col-span-1">
            <a :href="`${socialLoginUrl}/google`" class="w-full inline-flex items-center justify-center py-3 px-4 space-x-4 text-sm border border-gray-300 rounded-full shadow-sm bg-white text-gray-500">
              <GoogleLoginIcon class="w-6 h-6" />
              <span class="font-roboto">Sign in with Google</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FacebookLoginIcon from '@/components/svgs/FacebookLoginIcon'
  import GoogleLoginIcon from '@/components/svgs/GoogleLoginIcon'
  export default {
    name: "Login",
    components: {
      FacebookLoginIcon,
      GoogleLoginIcon,
    },
    data() {
      return {
        loading: false,
        form: {
          email: null,
          password: null,
          device: 'spa',
        },
        error: ''
      }
    },
    methods: {
      login() {
        this.loading = true
        this.$store.dispatch('auth/login', this.form)
          .then(response => {
            if (response) {
              window.Event.$emit('login');
              this.$router.push({name: 'Home'})
              this.$store.dispatch('dashboard/bookings/fetchBookings')
              this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
            }

            this.startPendo();
          })
          .catch(errors => {
            this.error = errors.response.data.message
            this.$refs.loginForm.setErrors(errors.response.data.errors)
            this.form.password = null
          })
          .finally(() => this.loading = false)
      },

      startPendo() {
        if (! window.pendo || process.env.VUE_APP_ENV !== 'production') {
          return;
        }

        window.pendo.initialize({
          visitor: {
            id: this.user.id,
            email: this.user.email,
            full_name: `${this.user.first_name} ${this.user.last_name}`,
          },

          account: {
            id: this.user.id,
            name: `${this.user.first_name} ${this.user.last_name}`,
            is_paying: this.user.account_type === 'pro',
          }
        });
      },
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      socialLoginUrl() {
        return `${process.env.VUE_APP_API_ENDPOINT}/auth/login`
      },

      socialLoginEnabled() {
        return process.env.VUE_APP_SOCIAL_LOGIN_ENABLED
      },
    },
  }
</script>

<style scoped>


 @media only screen and (max-width: 320px) {
  button span{
    font-size: 17px;
  }
 }

</style>
